const testPermissions = [
    {
        "id": 1,
        "name": "categories_create",
        "group": "categories",
        "created_at": null,
        "updated_at": null,
        "label": "create",
        "mandatory": 0,
        "pivot": {
            "role_id": 4,
            "permission_id": 1
        }
    },
    {
        "id": 2,
        "name": "categories_list",
        "group": "categories",
        "created_at": null,
        "updated_at": null,
        "label": "list",
        "mandatory": 1,
        "pivot": {
            "role_id": 4,
            "permission_id": 2
        }
    },
    {
        "id": 3,
        "name": "categories_update",
        "group": "categories",
        "created_at": null,
        "updated_at": null,
        "label": "update",
        "mandatory": 0,
        "pivot": {
            "role_id": 4,
            "permission_id": 3
        }
    },
    {
        "id": 4,
        "name": "categories_delete",
        "group": "categories",
        "created_at": null,
        "updated_at": null,
        "label": "delete",
        "mandatory": 0,
        "pivot": {
            "role_id": 4,
            "permission_id": 4
        }
    }
]

export const checkPermission = (user, name) => {
    const tempUser = { ...user }
    if (tempUser.role?.name === "admin") return true

    const permissions = tempUser.role?.permissions || []

    let temp
    for (let i = 0; i < permissions.length; i++) {
        if (permissions.length === 0) {
            return true
        } else if (permissions[i].name === name) {
            temp = permissions[i];
        }
    }

    if (temp) return true
    else return false
}