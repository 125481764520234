import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import PdfViewer from "components/PDFViewer/PdfViewer";
import { postFormData } from "components/fetch";
import { checkPermission } from "helper/helper";
import { AuthContext } from "context/authContext";

function Privacy({ history }) {
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [link, setLink] = useState('')
    const { user } = useContext(AuthContext)


    const getPolicy = async () => {
        var response = await getData(`api/v1/admin/privacy-policy`)
        if (response.ok) {
            setLink(response.data.data.url);
        }
    }

    useEffect(() => {
        getPolicy();
        return () => {
            setLink('');
        }
    }, [])

    function onFileChange(event) {
        const { files } = event.target;
        if (files && files[0]) {
            setLink(files[0] || null);
        }
    }

    const Save = async () => {
        if (link && typeof link !== "string") {
            var response = await postFormData('api/v1/admin/privacy-policy', { file: link }, 'POST');
            if (response.ok) {
                history.push('/home');
            }
        } else {
            history.push('/home');
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div>
                                                <div>
                                                    <label htmlFor="file">Privacy and Policy</label>
                                                </div>
                                                <input onChange={onFileChange} type="file" />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            {
                                                checkPermission(user, "privacy_update") &&
                                                <Button color="primary" onClick={Save}>Save</Button>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <PdfViewer file={link} />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(Privacy);