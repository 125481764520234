import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch, { postFormData, deleteData } from "components/fetch";
import CustomModal from "components/Modal/CustomModal";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, ViewCarouselOutlined as ViewCarouselOutlinedIcon } from '@material-ui/icons';
import Button from "components/CustomButtons/Button.js";
import { checkPermission } from "helper/helper";
import { AuthContext } from "context/authContext";

function Categories({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [categories, setCategories] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [image, setImage] = useState(null)
    const [openModal, setOpenModal] = useState(false);

    const { user } = useContext(AuthContext)


    useScrollToTop(categories);

    var response = useFetch([page, search], `api/v1/admin/categories?page=${page}`);

    const DeleteCategory = async (id) => {
        var response = await deleteData(`api/v1/admin/categories/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }

    const savePhoto = async (categoryId, img) => {
        var response = await postFormData(`api/v1/admin/categories/${categoryId}/image`, { image: img.file });
        return response;
    }

    const handleOpenImageModal = (category) => {
        setSelectedCategory(category)
        setOpenModal(true)
    }

    const handleOnImageChange = async (img) => {
        setImage(img);
        await savePhoto(selectedCategory.id, img);
    }

    useEffect(() => {
        if (response && response.ok) {
            setCategories(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }, [response])

    useEffect(() => {
        if (selectedCategory)
            setImage(selectedCategory.image)
    }, [selectedCategory])



    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", "Remark", "Action"]}
                            tableData={
                                categories.map((category, index) => ({
                                    data: [category.name, category.remark],
                                    onClickEdit: () => history.push(`/category/edit/${category.id}`),
                                    onClickDelete: () => DeleteCategory(category.id),
                                    name: category.name,
                                    disableDelete: !checkPermission(user, "categories_delete"),
                                    addtionalActions: (
                                        <>
                                            {
                                                checkPermission(user, "categories_update") ?
                                                    <Button className='iconbtn' color="info" onClick={() => handleOpenImageModal(category)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                                    : <></>
                                            }
                                        </>
                                    ),
                                }))
                            }
                            disableCreate={!checkPermission(user, "categories_create")}
                            onClickCreate={() => history.push(`/category/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>

                <CustomModal open={openModal} onClose={() => { setOpenModal(false) }} style={{ alignItems: 'flex-start', marginTop: 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>Category Image</p>
                            <hr />
                            {
                                <SingleUpload value={image} onChange={handleOnImageChange} dataURLKey="url"
                                    style={{ width: 400, height: 168 }}
                                    unremoveable
                                    labelText={`Image`}
                                />
                            }
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}
export default withRouter(Categories);