import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom';
import { removeToken } from 'components/storage';
import { removeUserInfo } from 'components/storage';

export default function Logout() {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const Logout = () => {
        removeToken();
        removeUserInfo();
        setRedirectToLogin(true);
    }

    useEffect(Logout, []);

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (redirectToLogin) return <Redirect to={from} />
    return (
        <div>
        </div>
    )
}
