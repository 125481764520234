import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { CustomCheckBox } from "components/CustomCheckBox/CustomCheckBox";
import CardHeader from "components/Card/CardHeader";

function RoleForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')

    const [permissions, setPermissions] = useState()
    const [permissionKey, setPermissionKey] = useState()
    const [savePermissions, setSavePermissions] = useState([])

    const getUser = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/roles/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                let tempArr = [...response.data.data.permissions]
                let onlyIdArr = tempArr.map(item => item.id)
                setSavePermissions(onlyIdArr)
            }
        }
    }

    const getPermissions = async () => {
        var response = await getData(`api/v1/admin/permissions?withGroup=1`)
        if (response.ok) {
            setPermissions(response.data);
            const keys = Object.keys(response.data);
            setPermissionKey(keys)
        }
    }

    useEffect(() => {
        getUser(id);
        getPermissions();

        return () => {
            setName('');
            setNameError('')
        }
    }, [id])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')

        if (name) {
            var response = await fetchData(id ? `api/v1/admin/roles/${id}` : 'api/v1/admin/roles', { name, permissions: savePermissions }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/roles');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                {
                                    permissionKey && permissionKey.map((key, index) => (
                                        <GridItem xs={12} sm={12} md={4} key={index}>
                                            <GridContainer >
                                                <CardHeader >{key}</CardHeader>
                                                {
                                                    permissions[key].map((item, index) => (
                                                        <GridItem xs={12} sm={12} md={12} key={item.id}>
                                                            <CustomCheckBox
                                                                label={item.label}
                                                                value={
                                                                    savePermissions.indexOf(item.id) > -1 ? 1 : 0
                                                                }
                                                                onChange={e => {
                                                                    let tempArr = [...savePermissions];
                                                                    if (e.target.checked) {
                                                                        tempArr.push(item.id)
                                                                        setSavePermissions(tempArr)
                                                                    }
                                                                    else {
                                                                        const index = tempArr.indexOf(item.id);
                                                                        if (index > -1) { // only splice array when item is found
                                                                            tempArr.splice(index, 1); // 2nd parameter means remove one item only
                                                                        }
                                                                        setSavePermissions(tempArr)
                                                                    }
                                                                }}
                                                            />
                                                        </GridItem>
                                                    ))
                                                }

                                            </GridContainer>
                                        </GridItem>
                                    ))
                                }
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(RoleForm);