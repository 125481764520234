import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { CustomPaginateSelect } from "components/CustomSelect/CustomSelect";
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function SubCategoryForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [remark, setRemark] = useState('')
    const [nameError, setNameError] = useState('')
    const [category, setCategory] = useState(undefined)
    const [categoryError, setCategoryError] = useState('')
    const [image, setImage] = useState(null)
    const { user } = useContext(AuthContext)

    const GetSubCategory = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/sub_categories/${id}`)
            if (response.ok) {
                var { name, category, remark, image } = response.data.data;
                setName(name);
                setRemark(remark);
                setCategory({ label: category.name, value: category.id });
                setImage(image);
            }
        }
    }

    const savePhoto = async (categoryId) => {
        var response = await postFormData(`api/v1/admin/sub_categories/${categoryId}/image`, { image: image.file });
        return response;
    }

    useEffect(() => {
        GetSubCategory(id);
        return () => {
            setName('');
            setNameError('');
            setCategory(null);
            setCategoryError('');
            setImage(null);
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    useDidUpdateEffect(() => {
        setCategoryError(!category ? 'This field is required' : '')
        return () => {
            setCategoryError('')
        }
    }, [category])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setCategoryError(!category ? 'This field is required' : '')
        if (name && category) {
            var response = await fetchData(id ? `api/v1/admin/sub_categories/${id}` : 'api/v1/admin/sub_categories', { name, category_id: category?.value, remark }, (id ? 'PUT' : 'POST'));
            if (response.ok && image.file) {
                response = await savePhoto(id ? id : response.data.data.id);
                if (response.ok) {
                    return history.push('/sub_categories');
                }
            }
            return history.push('/sub_categories');
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomAutoComplete
                                        labelText="Category *"
                                        id="category"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={category}
                                        onChange={(event, value) => setCategory(value)}
                                        onBlur={() => setCategoryError(!category ? 'This field is required' : '')}
                                        url={`api/v1/admin/categories`}
                                        labelField='name'
                                        valueField='id'
                                        errorText={categoryError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url"
                                        style={{ width: 400, height: 168 }}
                                        unremoveable
                                        labelText={`Sub Category Image`}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "sub_categories_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(SubCategoryForm);