import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function PaymentMethodForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [no, setNo] = useState('')
    const [noError, setNoError] = useState('')
    const [accName, setAccName] = useState('')
    const [accNameError, setAccNameError] = useState('')
    const [bank, setBank] = useState('')
    const [bankError, setBankError] = useState('')
    const [image, setImage] = useState(null)
    const { user } = useContext(AuthContext)


    const GetPaymentMethod = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/payment_methods/${id}`)
            if (response.ok) {
                var { name, acc_name, acc_no, bank_name, image } = response.data.data;
                setName(name);
                setAccName(acc_name);
                setNo(acc_no);
                setBank(bank_name);
                setImage(image);
            }
        }
    }

    const savePhoto = async (paymentMethodId) => {
        var response = await postFormData(`api/v1/admin/payment_methods/${paymentMethodId}/image`, { image: image.file });
        return response;
    }

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setNoError(!no ? 'This field is required' : '')
        setBankError(!bank ? 'This field is required' : '')
        setAccNameError(!accName ? 'This field is required' : '')
        if (name && no && accName && bank) {
            var response = await fetchData(id ? `api/v1/admin/payment_methods/${id}` : 'api/v1/admin/payment_methods',
                { acc_name: accName, acc_no: no, bank_name: bank, name }, (id ? 'PUT' : 'POST'));
            if (response.ok && image.file) {
                response = await savePhoto(id ? id : response.data.data.id);
                if (response.ok) {
                    return history.push('/payment_methods');
                }
            }
            return history.push('/payment_methods');
        }
    }

    useEffect(() => {
        GetPaymentMethod(id);
        return () => {
            setName('');
            setNameError('');
            setAccName('');
            setAccNameError('');
            setNo('');
            setNoError('');
            setBank('');
            setBankError('');
            setImage(null);
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    useDidUpdateEffect(() => {
        setNoError(!no ? 'This field is required' : '')
        return () => {
            setNoError('')
        }
    }, [no])

    useDidUpdateEffect(() => {
        setBankError(!bank ? 'This field is required' : '')
        return () => {
            setBankError('')
        }
    }, [bank])

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Paymente Method *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Bank Name *"
                                        id="bank_name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: bank || '',
                                            onChange: e => setBank(e.target.value),
                                            onBlur: e => setBankError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={bankError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Account Name *"
                                        id="accName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: accName || '',
                                            onChange: e => setAccName(e.target.value),
                                            onBlur: e => setAccNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={accNameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText={'Account No *'}
                                        id="no"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: no,
                                            onChange: e => setNo(e.target.value),
                                            onBlur: e => setNoError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={noError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url"
                                        // resolutionProps={resolution.home_banner}
                                        style={{ width: 400, height: 168 }}
                                        unremoveable
                                        labelText={`Payment Method QR`}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "payment_methods_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(PaymentMethodForm);