import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function TestimonialForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [rating, setRating] = useState('')
    const [image, setImage] = useState(null)
    const { user } = useContext(AuthContext)

    const GetCategory = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/testimonials/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setImage(response.data.data.image)
                setText(response.data.data.text)
                setRating(response.data.data.rating)
            }
        }
    }

    useEffect(() => {
        GetCategory(id);
        return () => {
            setName('');
            setImage(null);
            setText('');
            setRating('');
        }
    }, [id])

    const Save = async () => {
        if (image) {
            var response = await postFormData(id ? `api/v1/admin/testimonials/${id}` : 'api/v1/admin/testimonials', { name: name, text: text, rating: Number(rating), image: image.file });
            if (response.ok) {
                return history.push('/testimonials');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Name"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Text"
                                        id="text"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: text || '',
                                            onChange: e => setText(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Rating"
                                        id="rating"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: rating || '',
                                            onChange: e => setRating(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="path"
                                        style={{ width: 400, height: 168 }}
                                        unremoveable
                                        labelText={`Image`}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "testimonials_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(TestimonialForm);