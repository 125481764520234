import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import {
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { getData, postData, download } from "components/fetch";
import DataTable from "components/Table/DataTable";
import { Assignment as AssignmentIcon } from "@material-ui/icons";
import CustomModal from "components/Modal/CustomModal";
import { OpenInNew } from "@material-ui/icons";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";
const Orders = ({ history }) => {
  const [orderStatuses, setOrdersStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("new");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderID, setSelectedOrderID] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openAcceptConfirmModal, setOpenAcceptConfirmModal] = useState(false);
  const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState(false);
  const [openDeliveredConfirmModal, setOpenDeliveredConfirmModal] = useState(false);
  const [isDetailLoading, setIsDetailLoaing] = useState(false);
  const { user } = useContext(AuthContext)


  const getOrdersStatuses = async () => {
    const response = await getData("api/v1/admin/orders/statuses");
    if (response.ok) {
      setOrdersStatuses(response.data.data);
    }
  };

  const getOrders = async () => {
    const response = await getData(
      `api/v1/admin/orders?start_date=&end_date=&status=${selectedStatus}&page=${page}`
    );
    if (response.ok) {
      setOrders(response.data.data);
    }
  };

  const getOrder = async () => {
    setIsDetailLoaing(true)
    const response = await getData(`api/v1/admin/orders/${selectedOrderID}`);
    if (response.ok) {
      setSelectedOrder(response.data.data);
    }
    setIsDetailLoaing(false)
  };

  const handleOpenImageModal = (orderId) => {
    setSelectedOrderID(orderId);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = async () => {
    setOpenDetailModal(false);
    setSelectedOrderID(null);
    setSelectedOrder(null);
  };

  const chagneOrderStatus = async (status) => {
    const response = await postData(`api/v1/admin/orders/${selectedOrder.id}/status`,
      {
        "status": status
      }
    );

    if (response.ok) {
      setOpenDetailModal(false)
      setOpenAcceptConfirmModal(false)
      setOpenCancelConfirmModal(false)
      setOpenDeliveredConfirmModal(false)
      setSelectedOrderID(null);
      setSelectedOrder(null);
      getOrders()
    }
  }

  const downloadReceipt = () => {
    download(`api/v1/admin/orders/${selectedOrder.id}/invoice`, `invoice_#${selectedOrder.id}.xlsx`);
  }

  useEffect(() => {
    getOrdersStatuses();
    getOrders();
    return () => {
      setOrdersStatuses([]);
      setOrders([]);
      setSelectedOrder(null);
      setSelectedOrderID(null);
    };
  }, []);

  useEffect(() => {
    if (selectedOrderID) {
      getOrder();
    }
  }, [selectedOrderID]);

  useEffect(() => {
    if (selectedStatus) {
      getOrders();
    }
  }, [selectedStatus]);

  useEffect(() => {
    getOrders();
  }, [page]);

  const isLoggedIn = useIsLoggedIn();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/login" } };
  if (!isLoggedIn) return <Redirect to={from} />;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    id="state-label"
                    shrink={selectedStatus != (null | "")}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="state-label"
                    id="state-id"
                    value={selectedStatus}
                    label="State"
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                    }}
                  >
                    {orderStatuses.map((s) => (
                      <MenuItem key={s.name} value={s.name}>
                        {s.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <DataTable
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "Customer",
                    "Phone",
                    "Email",
                    "Date",
                    "Action",
                  ]}
                  tableData={orders.map((order, index) => ({
                    data: [
                      order.id,
                      order.name,
                      order.phone,
                      order.email,
                      order.order_date,
                    ],
                    disableEdit: true,
                    disableDelete: true,
                    name: order.name,
                    id: order.id,
                    addtionalActions: (
                      <>
                        <Button
                          className="iconbtn"
                          color="info"
                          onClick={() => handleOpenImageModal(order.id)}
                          tooltip={{ title: "Images" }}
                        >
                          <AssignmentIcon />
                        </Button>
                      </>
                    ),
                  }))}
                  count={count}
                  page={page}
                  onPaginationChange={async (event, value) => {
                    await setPage(value);
                  }}
                  disableSearch={true}
                  disableCreate={true}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <CustomModal
          open={openDetailModal}
          onClose={handleCloseDetailModal}
          style={{ alignItems: "flex-start", marginTop: 150 }}
          modalBodyStyle={{ width: 800, maxWidth: "90%" }}
        >
          <GridContainer style={{ justifyContent: "center" }}>
            {isDetailLoading &&
              <GridItem >
                <CircularProgress />
              </GridItem>
            }
            <GridItem md={12} style={{ width: "100%" }}>

              {selectedOrder && (
                <GridContainer>
                  <GridItem xs={12} style={{ width: "100%" }}> Order #{selectedOrder.id} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Name </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.name} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Name </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.name} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Phone </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.phone} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Email </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.email} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Address Line 1 </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.address_line_1} </GridItem>

                  <GridItem xs={6} style={{ width: "100%" }}> Address Line 2 </GridItem>
                  <GridItem xs={6} style={{ width: "100%" }}> {selectedOrder.address_line_2} </GridItem>


                  {selectedOrder.payment_method &&
                    <>
                      <GridItem xs={6} style={{ width: "100%" }}> Payment Method </GridItem>
                      <GridItem xs={6} style={{ width: "100%" }}> <a target="_blank" href={"/payment_method/edit/" + selectedOrder.payment_method.id}> {selectedOrder.payment_method.name}  </a></GridItem>
                    </>
                  }

                  {selectedOrder.images.map((image) => {
                    return <GridItem xs={3}>
                      <a href={image.url} target="_blacnk"><img src={image.url} style={{ width: "100%" }}></img></a>
                    </GridItem>
                  })
                  }


                  <GridItem xs={12} style={{ width: "100%" }}>
                    <table style={{ border: "1px solid", width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          selectedOrder.order_items.map((order_item) => {
                            return <tr style={{ height: "2rem" }}>
                              <td>{order_item.variation.product.name} {order_item.variation.name}</td>
                              <td style={{ textAlign: "right" }}>{order_item.formatted_qty}</td>
                              <td style={{ textAlign: "right" }}>{order_item.formatted_price}</td>
                              <td style={{ textAlign: "right" }}>{order_item.formatted_amount}</td>
                            </tr>
                          })
                        }
                        <tr style={{ textAlign: "right" }}>
                          <td></td>
                          <td></td>
                          <td style={{ borderTop: "1px solid" }}>Sub Total</td>
                          <td style={{ borderTop: "1px solid" }}>{selectedOrder.formatted_sub_total}</td>
                        </tr>
                        <tr style={{ textAlign: "right" }}>
                          <td></td>
                          <td></td>
                          <td>Delivery Fee</td>
                          <td>{selectedOrder.formatted_delivery_fee}</td>
                        </tr>
                        <tr style={{ textAlign: "right" }}>
                          <td></td>
                          <td></td>
                          <td>Total</td>
                          <td>{selectedOrder.formatted_total}</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridItem>

                  <GridItem style={{ paddingTop: "5px" }} xs={12}>
                    <div className='pull-right'>
                      {
                        checkPermission(user, "orders_update") &&
                        selectedOrder &&
                        <>
                          {selectedOrder.status == 'new' && <Button color="danger" onClick={() => { setOpenCancelConfirmModal(true) }}>Cancel</Button>}
                          {(selectedOrder.status == 'accepted' || selectedOrder.status == 'delivered') && <Button color="info" onClick={() => { downloadReceipt() }}>Receipt</Button>}
                          {selectedOrder.status == 'new' && <Button color="info" onClick={() => { setOpenAcceptConfirmModal(true) }}>Accept</Button>}
                          {selectedOrder.status == 'accepted' && <Button color="info" onClick={() => { setOpenDeliveredConfirmModal(true) }}>Delivered</Button>}
                        </>
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
        </CustomModal>

        <CustomModal
          open={openAcceptConfirmModal}
          onClose={() => { setOpenAcceptConfirmModal(false) }}
          style={{ alignItems: "flex-start", marginTop: "20%" }}
          modalBodyStyle={{ width: 600, maxWidth: "90%" }}>
          <GridContainer>
            <GridItem md={12} style={{ width: "100%" }}>
              Are you sure you want to accept this order?
            </GridItem>
            <GridItem md={12} style={{ width: "100%" }}>
              <div className='pull-right'>
                <Button color="secondary" onClick={() => { setOpenAcceptConfirmModal(false) }}>Close</Button>
                <Button color="info" onClick={() => { chagneOrderStatus('accepted') }}>Accept</Button>
              </div>
            </GridItem>
          </GridContainer>
        </CustomModal>

        <CustomModal
          open={openCancelConfirmModal}
          onClose={() => { setOpenCancelConfirmModal(false) }}
          style={{ alignItems: "flex-start", marginTop: "20%" }}
          modalBodyStyle={{ width: 600, maxWidth: "90%" }}>
          <GridContainer>
            <GridItem md={12} style={{ width: "100%" }}>
              Are you sure you want to cancel this order?
            </GridItem>
            <GridItem md={12} style={{ width: "100%" }}>
              <div className='pull-right'>
                <Button color="secondary" onClick={() => { setOpenCancelConfirmModal(false) }}>Close</Button>
                <Button color="info" onClick={() => { chagneOrderStatus('canceled') }}>Cancel</Button>
              </div>
            </GridItem>
          </GridContainer>
        </CustomModal>

        <CustomModal
          open={openDeliveredConfirmModal}
          onClose={() => { setOpenDeliveredConfirmModal(false) }}
          style={{ alignItems: "flex-start", marginTop: "20%" }}
          modalBodyStyle={{ width: 600, maxWidth: "90%" }}>
          <GridContainer>
            <GridItem md={12} style={{ width: "100%" }}>
              Are you sure you want to set this order as delivered?
            </GridItem>
            <GridItem md={12} style={{ width: "100%" }}>
              <div className='pull-right'>
                <Button color="secondary" onClick={() => { setOpenDeliveredConfirmModal(false) }}>Close</Button>
                <Button color="info" onClick={() => { chagneOrderStatus('delivered') }}>OK</Button>
              </div>
            </GridItem>
          </GridContainer>
        </CustomModal>
      </GridItem>
    </GridContainer>
  );
};

export default withRouter(Orders);
