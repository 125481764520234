import { useIsLoggedIn } from 'components/Hooks/useIsLoggedIn';
import React, { useEffect, useState } from 'react'
import { Redirect, useLocation, withRouter } from 'react-router-dom'
import { Card, InputLabel } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getData } from 'components/fetch';
import CardHeader from 'components/Card/CardHeader';
import DataTable from 'components/Table/DataTable';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';

const Home = ({ history }) => {

    const [ todayNumber, setTodayNumber ] = useState(0);
    const [ weekNumber, setWeekNumber ] = useState(0);
    const [ monthNumber, setMonthNumber ] = useState(0);
    const [ topItemStartDate, setTopItemStartDate ] = useState(moment().format('YYYY-MM-DD'));
    const [ topItemEndDate, setTopItemEndDate ] = useState(moment().format('YYYY-MM-DD'));
    const [ topProducts, setTopProducts ] = useState([]);
    const [ topProductsLoading, setTopProductsLoading ] = useState(true)
    
    useEffect( () => {
        getNumbers();
        getTopProducts();
        return () => {
            setTodayNumber(0);
            setWeekNumber(0);
            setMonthNumber(0);
        }
    }, []);

    const getTopProductsUrl = () => {
        let base = "api/v1/admin/dashboard/top_products"
        let firstAppend = true;
        if(topItemStartDate) {
            base = `${base}?start_date=${topItemStartDate}`;
            firstAppend = false;
        }
        if(topItemEndDate) {
            base = firstAppend ? `${base}?end_date=${topItemEndDate}` : `${base}&end_date=${topItemEndDate}`
        }
        return base;
    }

    const getNumbers = async () => {
        const response = await getData("api/v1/admin/dashboard/sales");
        if(!response.ok) return;
        setTodayNumber(response.data.data.today);
        setWeekNumber(response.data.data.this_week);
        setMonthNumber(response.data.data.this_month);
    }

    const getTopProducts = async () => {
        const response = await getData(getTopProductsUrl());
        if(response.ok) {
            setTopProducts(response.data.data);
        }
        setTopProductsLoading(false);
    }

    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader>
                        Total Sale Today
                    </CardHeader>
                    <CardBody>
                        {todayNumber}
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader>
                        Total Sale This Week
                    </CardHeader>
                    <CardBody>
                        {weekNumber}
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader>
                        Total Sale This Month
                    </CardHeader>
                    <CardBody>
                        {monthNumber}
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card style={{marginTop : "20px"}}>
                    <CardHeader>
                        Top Products
                    </CardHeader>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <InputLabel shrink classes={{ root: 'custom-label' }}>Start Date</InputLabel>
                                    <CustomInput
                                        id="startDate"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: topItemStartDate || '',
                                            onChange: e => setTopItemStartDate(e.target.value),
                                        }}
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <InputLabel shrink classes={{ root: 'custom-label' }}>End Date</InputLabel>
                                        <CustomInput
                                            id="endDate"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: 'date',
                                                value: topItemEndDate || '',
                                                onChange: e => setTopItemEndDate(e.target.value),
                                            }}
                                        />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}  style={{alignSelf: "flex-end"}}>
                                <Button  loading={true} color="primary" onClick={() => {getTopProducts()}}>Go</Button >
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <DataTable
                                tableHeaderColor="primary"
                                tableHead={["No.", "Name", 'Short Description', 'Gems', 'Materials']}
                                tableData={
                                    topProducts.map((product, index) => ({
                                        data: [
                                            index+1,
                                            product.name, 
                                            product.short_description.length >= 70 ? product.short_description.substring(0, 70)+"..." : product.short_description, 
                                            product.gems.map(gem => gem.name).join(), 
                                            product.materials.map(material => material.name).join(),
                                        ],
                                        name: product.name,
                                        id: product.id,
                                        disableDelete: true,
                                        disableEdit: true
                                    }))
                                }
                                disableCreate ={true}
                                disablePagination ={true}
                                disableSearch ={true}
                            />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default withRouter(Home)
