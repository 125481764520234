import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import { getData, deleteData } from "components/fetch";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";


function Carousels({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [carousels, setCarousels] = useState([]);
    const { user } = useContext(AuthContext)

    useScrollToTop(carousels);

    const getCarousels = async () => {
        let response = await getData(`api/v1/admin/carousels`);
        if (response && response.ok) {
            setCarousels(response.data.data);
        }
    }

    const deleteCarousel = async (id) => {
        let response = await deleteData(`api/v1/admin/carousels/${id}`);
        if (response && response.ok) {
            getCarousels();
        }
    }


    useEffect(() => {
        getCarousels();
        return () => {
            setCarousels([]);
        }
    }, [])


    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Text", "Image", "Action"]}
                            tableData={
                                carousels.map((carousel, index) => ({
                                    data: [
                                        carousel.text,
                                        <img style={{ width: "200px" }} src={carousel.image.url} alt={carousel} />
                                    ],
                                    onClickEdit: () => history.push(`/carousel/edit/${carousel.id}`),
                                    onClickDelete: () => deleteCarousel(carousel.id),
                                    disableDelete: !checkPermission(user, "carousels_delete"),
                                    name: carousel.text
                                }))
                            }
                            disableCreate={!checkPermission(user, "carousels_create")}
                            onClickCreate={() => history.push(`/carousel/create`)}
                            disablePagination={true}
                            disableSearch={true}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
export default withRouter(Carousels);
