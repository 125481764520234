import React, { useCallback, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { useResizeObserver } from '@wojtekmaj/react-hooks'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
}

const maxWidth = 1000

export default function PdfViewer({ file }) {
    const [numPages, setNumPages] = useState()
    const [containerWidth, setContainerWidth] = useState()
    const [containerRef, setContainerRef] = useState(null)

    const onResize = useCallback((entries) => {
        const [entry] = entries
        if (entry) {
            setContainerWidth(entry.contentRect.width)
        }
    }, [])

    useResizeObserver(containerRef, {}, onResize)

    function onDocumentLoadSuccess({
        numPages: nextNumPages,
    }) {
        setNumPages(nextNumPages)
    }

    return (
        <div className="container" ref={setContainerRef}>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className="d-flex justify-content-center"
                        width={
                            containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                        }
                    />
                ))}
            </Document>
        </div>
    )
}
