import { getData } from "components/fetch";
import { getUserInfo } from "components/storage";
import { setUserInfo } from "components/storage";
import React, { createContext, useEffect, useMemo, useState } from "react";

export const AuthContext = createContext(); // could have a default value

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();


    useEffect(() => {
        if (!user) {
            setUser(JSON.parse(getUserInfo()))
        }
    }, [])

    const saveUser = async () => {
        var response = await getData(`api/v1/admin/profile`)
        if (response.ok) {
            setUser(response.data.data);
            setUserInfo(JSON.stringify(response.data.data))
        }
    }

    const value = useMemo(() => ({
        user, saveUser
    }), [user]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
