import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            if (window) {
                window.scrollTo(0, 0);
            }
            if (document.getElementById('app-panel')) {
                document.getElementById('app-panel').scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        }
    }, []);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);

export function useScrollToTop(value) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('app-panel').scrollTo(0, 0);
    }, [value])
    return null;
}