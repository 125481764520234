import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { Grid } from "@material-ui/core";
import { validateEmail } from 'components/common';
import { setToken } from "components/storage";
import { Redirect, useLocation } from "react-router-dom";
import { postData } from "components/fetch";
import { AuthContext } from "context/authContext";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function Login(props) {
    const classes = useStyles();
    let location = useLocation();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [redirectToIndex, setRedirectToIndex] = useState(false)
    const { saveUser } = useContext(AuthContext)

    useEffect(() => {
        setEmailError('');
    }, [email])

    useEffect(() => {
        setPasswordError('');
    }, [password])

    const Login = async () => {
        setEmailError(!email ? 'This field is required' : !validateEmail(email) ? 'Email is not in a correct format' : '')
        setPasswordError(!password ? 'This field is required' : '');
        if (email && validateEmail(email) && password) {
            var response = await postData('api/v1/admin/login', { email, password });
            if (response.ok) {
                setToken(response.data.token);
                await saveUser();
                setRedirectToIndex(true);
            } else {
                alert('Username and password are not matching');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/dashboard" } };
    if (redirectToIndex) return <Redirect to={from} />
    return (
        <div>
            <Grid container style={{ justifyContent: 'center', alignItem: 'center', marginTop: 50 }}>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Login</h4>
                            <p className={classes.cardCategoryWhite}>Fill the followings to login</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'email',
                                            value: email,
                                            onChange: e => setEmail(e.target.value),
                                        }}
                                        errorText={emailError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Password"
                                        id="password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'password',
                                            onChange: e => setPassword(e.target.value)
                                        }}
                                        errorText={passwordError}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter style={{ justifyContent: 'center' }}>
                            <Button color="primary" onClick={Login}>Login</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>
        </div>
    );
}
