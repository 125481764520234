import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { deleteData } from "components/fetch";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch from "components/fetch";
import Button from "components/CustomButtons/Button.js";
import { getData } from "components/fetch";
import CustomModal from "components/Modal/CustomModal";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { postData } from "components/fetch";
import { postFormData } from "components/fetch";
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, ViewCarouselOutlined as ViewCarouselOutlinedIcon } from '@material-ui/icons';
import resolution from "components/resolution";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { Box, InputLabel, Typography } from "@material-ui/core";
import { download } from "components/fetch";
import moment from "moment/moment";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function TabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function Reports({ history }) {
    const [currentTab, setCurrentTab] = useState(0);
    const [productSaleStartDate, setProductSaleStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [productSaleEndDate, setProductSaleEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [dailySaleStartDate, setDailySaleStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [dailySaleEndDate, setDailySaleEndDate] = useState(moment().format('YYYY-MM-DD'));
    const { user } = useContext(AuthContext)

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const downloadDailySales = () => {
        download(`api/v1/admin/reports/daily-sales?start_date=${dailySaleStartDate}&end_date=${dailySaleEndDate}`, `daily_sales_${dailySaleStartDate}_${dailySaleEndDate}.csv`);
    }

    const downloadProudctSales = () => {
        download(`api/v1/admin/reports/product-sales?start_date=${productSaleStartDate}&end_date=${productSaleEndDate}`, `product_sales_${productSaleStartDate}_${productSaleEndDate}.csv`);
    }


    return <>
        <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="Daily Sales" />
                <Tab label="Product Sales" />
            </Tabs>

            <TabPanel value={currentTab} index={0}>
                <CardHeader>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <InputLabel shrink classes={{ root: 'custom-label' }}>Start Date</InputLabel>
                            <CustomInput
                                id="startDate"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'date',
                                    value: dailySaleStartDate || '',
                                    onChange: e => setDailySaleStartDate(e.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <InputLabel shrink classes={{ root: 'custom-label' }}>End Date</InputLabel>
                            <CustomInput
                                id="endDate"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'date',
                                    value: dailySaleEndDate || '',
                                    onChange: e => setDailySaleEndDate(e.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} style={{ alignSelf: "flex-end" }}>
                            {
                                checkPermission(user, "daily_sale_reports_show") &&
                                <Button loading={true} color="primary" onClick={() => { downloadDailySales() }}>Download</Button >
                            }
                        </GridItem>
                    </GridContainer>
                </CardHeader>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <CardHeader>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <InputLabel shrink classes={{ root: 'custom-label' }}>Start Date</InputLabel>
                            <CustomInput
                                id="startDate"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'date',
                                    value: productSaleStartDate || '',
                                    onChange: e => setProductSaleStartDate(e.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <InputLabel shrink classes={{ root: 'custom-label' }}>End Date</InputLabel>
                            <CustomInput
                                id="endDate"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: 'date',
                                    value: productSaleEndDate || '',
                                    onChange: e => setProductSaleEndDate(e.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} style={{ alignSelf: "flex-end" }}>
                            {
                                checkPermission(user, "product_reports_show") &&
                                <Button loading={true} color="primary" onClick={() => { downloadProudctSales() }}>Download</Button >
                            }
                        </GridItem>
                    </GridContainer>
                </CardHeader>
            </TabPanel>
        </Card>
    </>
}

export default withRouter(Reports)