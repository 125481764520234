import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import PdfViewer from "components/PDFViewer/PdfViewer";
import '../../assets/css/pdfviewer.css'
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function CategoryForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [remark, setRemark] = useState('')
    const [link, setLink] = useState('')
    const [image, setImage] = useState(null)
    const [file, setFile] = useState();
    const { user } = useContext(AuthContext)

    const GetCategory = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/categories/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setRemark(response.data.data.remark);
                setLink(response.data.data.link);
                setImage(response.data.data.image)
                setFile(response.data.data.size_chart)
                // fetch(response.data.data.size_chart)
                //     .then((res) => res.blob())
                //     .then((res) => {
                //         let file = new File([res], "size_chart.pdf", { type: res.type });
                //         setFile(file)
                //     })
            }
        }
    }

    useEffect(() => {
        GetCategory(id);
        return () => {
            setName('');
            setNameError('');
            setRemark('');
            setLink('');
            setImage(null);
            setFile(null)
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    const savePhoto = async (categoryId) => {
        var response = await postFormData(`api/v1/admin/categories/${categoryId}/image`, { image: image.file });
        return response;
    }

    const saveSizeChart = async (categoryId) => {
        var response = await postFormData(`api/v1/admin/categories/${categoryId}/size-chart`, { file });
        return response;
    }

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        if (name) {
            var response = await fetchData(id ? `api/v1/admin/categories/${id}` : 'api/v1/admin/categories', { name, remark, link }, (id ? 'PUT' : 'POST'));
            var responsePhoto, responseSizeChart
            if (response.ok && image.file) {
                responsePhoto = await savePhoto(id ? id : response.data.data.id);
                if (responsePhoto.ok) {
                    // return history.push('/categories');
                }
            }
            if (response.ok && file) {
                if (typeof file !== 'string') {

                    responseSizeChart = await saveSizeChart(id ? id : response.data.data.id);
                    if (responseSizeChart.ok) {
                        // return history.push('/categories');
                    }
                }
            }
            return history.push('/categories');
        }
    }

    function onFileChange(event) {
        const { files } = event.target;
        if (files && files[0]) {
            setFile(files[0] || null);
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                    <Card>
                        <CardBody>
                            <GridContainer >
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Link"
                                        id="link"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: link || '',
                                            onChange: e => setLink(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url"
                                        // resolutionProps={resolution.home_banner}
                                        style={{ width: 400, height: 168 }}
                                        unremoveable
                                        labelText={`Category Image`}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className="">
                                        <div>
                                            <label htmlFor="file">Size Chart</label>
                                        </div>
                                        <input onChange={onFileChange} type="file" />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "categories_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                    <Card>
                        <CardBody>
                            <div className="pdf-container">
                                <PdfViewer file={file} />
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(CategoryForm);