import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { InputLabel } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function CustomerPasswordForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [passwordError, setPasswordError] = useState('')
    const [password, setPassword] = useState('')
    const { user } = useContext(AuthContext)


    useDidUpdateEffect(() => {
        setPasswordError(!password ? 'This field is required' : '')
        return () => {
            setPasswordError('')
        }
    }, [password])

    const Save = async () => {
        setPasswordError(!password ? 'This field is required' : '')

        if (password) {
            var response = await fetchData(`api/v1/admin/customers/${id}/password`, {
                password,
            }, 'POST');
            if (response.ok) {
                history.push('/customers');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader><h4>Change Password</h4></CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Password *"
                                        id="password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: password || '',
                                            onChange: e => setPassword(e.target.value),
                                            onBlur: e => setPasswordError(!password ? 'This field is required' : '')
                                        }}
                                        errorText={passwordError}
                                    />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "customers_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(CustomerPasswordForm);