const mainCarousel = {
    resolutionType: 'ratio',
    resolutionWidth: 1920,
    resolutionHeight: 800
}
const mainPoster = {
    resolutionType: 'ratio',
    resolutionWidth: 1200,
    resolutionHeight: 500
}
const brandCarousel = {
    resolutionType: 'ratio',
    resolutionWidth: 1920,
    resolutionHeight: 850
}
const brand = {
    resolutionType: 'ratio',
    resolutionWidth: 626,
    resolutionHeight: 310
}
const product = {
    resolutionType: 'ratio',
    resolutionWidth: 300,
    resolutionHeight: 300
}
const home_banner = {
    resolutionType: 'ratio',
    resolutionWidth: 482,
    resolutionHeight: 202
}
const brand_best_product_banner = {
    resolutionType: 'ratio',
    resolutionWidth: 370,
    resolutionHeight: 400
}
const deal_of_a_day_home = {
    resolutionType: 'ratio',
    resolutionWidth: 570,
    resolutionHeight: 525
}
const deal_of_a_day_brand = {
    resolutionType: 'ratio',
    resolutionWidth: 372,
    resolutionHeight: 372
}
const more_beauty_for_you_banner = {
    resolutionType: 'ratio',
    resolutionWidth: 221,
    resolutionHeight: 404
}
export default {
    mainCarousel,
    mainPoster,
    brandCarousel,
    brand,
    product,
    home_banner,
    brand_best_product_banner,
    deal_of_a_day_home,
    deal_of_a_day_brand,
    more_beauty_for_you_banner,
}