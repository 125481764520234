import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData } from "components/fetch";
import { fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

function UserForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [role_id, setRole_id] = useState('')
    const [roleError, setRoleError] = useState('')

    const [roles, setRoles] = useState()

    const getUser = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/users/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setEmail(response.data.data.email);
                setPhone(response.data.data.phone);
                setPassword(response.data.data.name);
                setRole_id(response.data.data.role.id);
            }
        }
    }

    const getRole = async () => {

        var response = await getData(`api/v1/admin/roles`)
        if (response.ok) {
            setRoles(response.data.data)
        }

    }

    useEffect(() => {
        getUser(id);
        getRole()
        return () => {
            setName('');
            setNameError('');
            setEmail('');
            setEmailError('');
            setPhone('');
            setPhoneError('');
            setPassword('');
            setPasswordError('');
            setRole_id('');
            setRoleError('');

        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        setEmailError(!email ? 'This field is required' : '')
        // setPhoneError(!phone ? 'This field is required' : '')
        setPasswordError(!password ? 'This field is required' : '')
        setRoleError(!role_id ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name, email, password, role_id])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setEmailError(!email ? 'This field is required' : '')
        // setPhoneError(!phone ? 'This field is required' : '')
        setPasswordError(!password ? 'This field is required' : '')
        setRoleError(!role_id ? 'This field is required' : '')
        if (id) {
            if (name && email && role_id) {
                var response = await fetchData(id ? `api/v1/admin/users/${id}` : 'api/v1/admin/users', id ? { name, email, phone, role_id: Number(role_id) } : { name, email, phone, password, role_id: Number(role_id) }, (id ? 'PUT' : 'POST'));
                if (response.ok) {
                    history.push('/users');
                }
            }
        } else {
            if (name && email && password && role_id) {
                var response = await fetchData(id ? `api/v1/admin/users/${id}` : 'api/v1/admin/users', id ? { name, email, phone, role_id: Number(role_id) } : { name, email, phone, password, role_id: Number(role_id) }, (id ? 'PUT' : 'POST'));
                if (response.ok) {
                    history.push('/users');
                }
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Email *"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: email || '',
                                            onChange: e => setEmail(e.target.value),
                                            onBlur: e => setEmailError(!email ? 'This field is required' : '')
                                        }}
                                        errorText={emailError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Phone *"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: phone || '',
                                            onChange: e => setPhone(e.target.value),
                                            onBlur: e => setPhoneError(!phone ? 'This field is required' : '')
                                        }}
                                        errorText={phoneError}
                                    />
                                </GridItem>
                                {
                                    !id ?
                                        <GridItem xs={12} sm={12} md={5}>
                                            <CustomInput
                                                labelText="Password *"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: password || '',
                                                    onChange: e => setPassword(e.target.value),
                                                    onBlur: e => setPasswordError(!password ? 'This field is required' : '')
                                                }}
                                                errorText={passwordError}
                                            />
                                        </GridItem> : null
                                }
                                <GridItem xs={12} sm={12} md={5}>

                                    <FormControl style={{ marginTop: "27px" }} fullWidth>
                                        <InputLabel id="state-label" shrink={0 != null}>Role</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            id="state-id"
                                            value={role_id}
                                            label="Role"
                                            onChange={(e) => { setRole_id(e.target.value) }}
                                        >
                                            {
                                                roles && roles.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>

                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={Save}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(UserForm);