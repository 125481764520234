import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function DeliveryForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState(null);
    const [stateIdError, setStateIdError] = useState('');
    const [township, setTownship] = useState('');
    const [townshipError, setTownshipError] = useState('');
    const [fee, setFee] = useState('');
    const [feeError, setFeeError] = useState('');
    const [remark, setRemark] = useState('');
    const { user } = useContext(AuthContext)


    const GetDelivery = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/delivery_townships/${id}`)
            if (response.ok) {
                setTownship(response.data.data.township);
                setStateId(response.data.data.state_id);
                setFee(response.data.data.fee);
                setRemark(response.data.data.remark);
            }
        }
    }

    const getStates = async () => {
        var response = await getData(`api/v1/admin/delivery_townships/states`);
        if (response.ok) {
            setStates(response.data.data);
        }
    }

    useEffect(() => {
        GetDelivery(id);
        return () => {
            setStateId(null);
        }
    }, [id])

    useDidUpdateEffect(() => {
        setTownshipError(!township ? 'This field is required' : '')
        return () => {
            setTownshipError('')
        }
    }, [township])

    useEffect(() => {
        getStates();
    }, [])

    const Save = async () => {
        setTownshipError(!township ? 'This field is required' : '')
        setFeeError(!fee ? 'This field is required' : '')
        setStateIdError(!stateId ? 'This field is required' : '')
        if (township) {
            var response = await fetchData(id ? `api/v1/admin/delivery_townships/${id}` : 'api/v1/admin/delivery_townships', { state_id: stateId, township, fee, remark }, 'POST');
            if (response.ok) {
                history.push('/deliveries');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="state-label" shrink={stateId != null}>State *</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            id="state-id"
                                            value={stateId}
                                            label="State"
                                            onChange={(e) => { setStateId(e.target.value) }}>
                                            {
                                                states.map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}><></></GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Township *"
                                        id="township"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: township || '',
                                            onChange: e => setTownship(e.target.value),
                                            onBlur: e => setTownshipError(!township ? 'This field is required' : '')
                                        }}
                                        errorText={townshipError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Fee *"
                                        id="fee"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: 'number',
                                            value: fee || '',
                                            onChange: e => setFee(e.target.value),
                                            onBlur: e => setFeeError(!fee ? 'This field is required' : '')
                                        }}
                                        errorText={feeError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Remark"
                                        id="remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: remark || '',
                                            onChange: e => setRemark(e.target.value)
                                        }}
                                        errorText={feeError}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "delivery_townships_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(DeliveryForm);