import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function ContactForm({ history }) {
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const { user } = useContext(AuthContext)


    const GetCategory = async () => {

        var response = await getData(`api/v1/admin/contact`)
        if (response.ok) {
            setPhone(response.data.phone);
            setEmail(response.data.email)
            setAddress(response.data.address)
            setLatitude(response.data.latitude)
            setLongitude(response.data.longitude)
        }

    }

    useEffect(() => {
        GetCategory();
        return () => {
            setPhone('');
            setEmail('');
            setAddress('');
            setLatitude('');
            setLongitude('');
        }
    }, [])

    const Save = async () => {
        if (latitude) {
            var response = await postFormData('api/v1/admin/contact', { phone: phone, email: email, address: address, latitude: latitude, longitude: longitude });
            if (response.ok) {
                return history.push('/home');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Phone"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: phone || '',
                                            onChange: e => setPhone(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: email || '',
                                            onChange: e => setEmail(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Address"
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: address || '',
                                            onChange: e => setAddress(e.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Latitude"
                                        id="latitude"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: latitude || '',
                                            onChange: e => setLatitude(e.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Longitude"
                                        id="longitude"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: longitude || '',
                                            onChange: e => setLongitude(e.target.value)
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "contact_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(ContactForm);