import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch, { deleteData, putData, getData, postFormData } from "components/fetch";
import CustomModal from "components/Modal/CustomModal";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function ProductVariations({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    const [variations, setVariations] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [openVariationModal, setOpenVariationModal] = useState(false);

    // Variation Form
    const [selectedVariationID, setSelectedVariationID] = useState(null);
    const [variation, setVariation] = useState(null);
    const [nameError, setNameError] = useState('');
    const [variationPriceError, setVariationPriceError] = useState('');
    const [variationStockError, setVariationStockError] = useState('');
    const { user } = useContext(AuthContext)


    useScrollToTop(variations);

    var response = useFetch([refreshCount], `api/v1/admin/products/${id}/variations`);
    useEffect(() => {
        if (response && response.ok) {
            setVariations(response.data.data);
        }
    }, [response])

    const DeleteVariation = async (id) => {
        var response = await deleteData(`api/v1/admin/variations/${id}`);
        if (response.ok) {
            setRefreshCount(count => count + 1);
        }
    }

    const GetVariation = async (id) => {
        var response = await getData(`api/v1/admin/variations/${id}`);
        if (response.ok) {
            setVariation(response.data.data);
        }
    }

    useEffect(() => {
        if (selectedVariationID) {
            GetVariation(selectedVariationID);
        } else {
            setVariation(null);
        }
    }, [selectedVariationID])

    const handleOpenVariationModal = (id) => {
        setOpenVariationModal(true);
        setSelectedVariationID(id);
    }

    const handleCloseVariationModal = () => {
        setOpenVariationModal(false);
        setSelectedVariationID(null);
        setRefreshCount(count => count + 1);
    }

    const handleInputVariation = (key, value) => {
        var v = variation || {};
        v[key] = value;
        setVariation(Object.assign({}, v));
    }

    useDidUpdateEffect(() => {
        setVariationPriceError(variation?.price ? '' : 'This field is required')
    }, [variation?.price])

    const SaveVariation = async () => {
        const { vid, name, price, stock, sku } = variation || {};
        setNameError(!name ? 'This field is required' : '')
        setVariationPriceError(!price ? 'This field is required' : '')
        setVariationStockError(!stock ? 'This field is required' : '')
        if (name && sku && price && stock) {
            var data = {
                name,
                product_id: id,
                price,
                stock,
                sku
            };
            if (vid) { // PUT
                var response = await putData(`api/v1/admin/variations/${vid}`, data);
                if (response.ok) {
                    setOpenVariationModal(false);
                    setRefreshCount(count => count + 1);
                }
            } else { // POST
                var response = await postFormData('api/v1/admin/variations', data);
                if (response.ok) {
                    setOpenVariationModal(false);
                    setRefreshCount(count => count + 1);
                }
            }
        }
    }

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Variation", "SKU", "Price", "Qty", "Action"]}
                            tableData={
                                variations.map((variation, index) => ({
                                    data: [variation.name, variation.sku, variation.price, variation.stock],
                                    onClickEdit: () => handleOpenVariationModal(variation.id),
                                    onClickDelete: () => DeleteVariation(variation.id),
                                    disableDelete: !checkPermission(user, "variations_delete"),
                                }))
                            }
                            disableCreate={!checkPermission(user, "variations_create")}
                            disablePagination
                            disableSearch
                            onClickCreate={() => handleOpenVariationModal()}
                        />
                    </CardBody>
                </Card>

                <CustomModal open={openVariationModal} onClose={handleCloseVariationModal} style={{ alignItems: 'flex-start', marginTop: selectedVariationID ? 0 : 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>{selectedVariationID ? 'Edit' : 'Create'} Variation</p>
                            <hr />
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Name *"
                                                id="name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: variation?.name || '',
                                                    onChange: e => handleInputVariation('name', e.target.value),
                                                    onBlur: e => setNameError(variation?.name ? '' : 'This field is required')
                                                }}
                                                errorText={nameError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="SKU"
                                                id="sku"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: variation?.sku || '',
                                                    onChange: e => handleInputVariation('sku', e.target.value)
                                                }}
                                                errorText={null}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Price *"
                                                id="price"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    value: variation?.price || '',
                                                    onChange: e => handleInputVariation('price', e.target.value),
                                                    onBlur: e => setVariationPriceError(variation?.price ? '' : 'This field is required')
                                                }}
                                                errorText={variationPriceError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Stock *"
                                                id="stock"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    value: variation?.stock || '',
                                                    onChange: e => handleInputVariation('stock', e.target.value),
                                                    onBlur: e => setVariationStockError(variation?.stock ? '' : 'This field is required')
                                                }}
                                                errorText={setVariationStockError}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {
                                                checkPermission(user, "variaions_update") &&
                                                <Button color="primary" onClick={SaveVariation}>Save</Button>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(ProductVariations)