import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import useFetch from "components/fetch";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import { deleteData } from "components/fetch";
import { getData } from "components/fetch";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function TestimonialList({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [testimonials, setTestimonials] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const { user } = useContext(AuthContext)

    useScrollToTop(testimonials);

    var response = useFetch([page, search], `api/v1/admin/testimonials?page=${page}`);

    const getTestimonials = async () => {
        let response = await getData(`api/v1/admin/testimonials`);
        if (response && response.ok) {
            setTestimonials(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }

    const DeleteTestimonial = async (id) => {
        var response = await deleteData(`api/v1/admin/testimonials/${id}`);
        if (response.ok) {
            getTestimonials();
        }
    }

    useEffect(() => {
        getTestimonials();
        // return () => {
        //     setTestimonials([]);
        // }
    }, [])


    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", "Rating", "Action"]}
                            tableData={
                                testimonials.map((item) => ({
                                    data: [item.name, item.rating],
                                    onClickEdit: () => history.push(`/testimonial/edit/${item.id}`),
                                    onClickDelete: async () => await DeleteTestimonial(item.id),
                                    disableDelete: !checkPermission(user, "testimonials_delete"),
                                    name: item.name
                                }))
                            }
                            disableCreate={!checkPermission(user, "testimonials_create")}
                            onClickCreate={() => history.push(`/testimonial/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
export default withRouter(TestimonialList);