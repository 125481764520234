export async function setToken(value) {
    await localStorage.setItem('beautytips_admin_token', value);
}

export function getToken() {
    return localStorage.getItem('beautytips_admin_token');
}

export function removeToken() {
    return localStorage.removeItem('beautytips_admin_token');
}

export async function setUserInfo(value) {
    await localStorage.setItem('user_info', value);
}

export function getUserInfo() {
    return localStorage.getItem('user_info');
}

export function removeUserInfo() {
    return localStorage.removeItem('user_info');
}
