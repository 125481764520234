import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { checkPermission } from "helper/helper";
import { AuthContext } from "context/authContext";

function CustomerForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [phone, setPhone] = useState('')
    const [address_line_1, set_address_line_1] = useState('')
    const [address_line_2, set_address_line_2] = useState('')
    const [addressError, setAddressError] = useState('')
    const [email, setEmail] = useState('')
    const [stateId, setStateId] = useState('')
    const [deliveryTownshipId, setDeliveryTownshipId] = useState('')

    const [states, setStates] = useState([]);
    const [deliveryTownships, setDeliveryTownships] = useState([]);
    const { user } = useContext(AuthContext)



    const getCustomer = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/customers/${id}`)
            if (response.ok) {
                setName(response.data.data.name);
                setPhone(response.data.data.phone);
                set_address_line_1(response.data.data.address_line_1);
                set_address_line_2(response.data.data.address_line_2);
                setEmail(response.data.data.email);
                setStateId(response.data.data.state_id)
                setDeliveryTownshipId(response.data.data.delivery_township_id)
            }
        }
    }

    const getStates = async () => {
        var response = await getData(`api/v1/admin/delivery_townships/states`);
        if (response.ok) {
            setStates(response.data.data);
        }
    }

    const getDeliveryTownships = async () => {
        var response = await getData(`api/v1/admin/delivery_townships?state_id=${stateId}`)
        if (response && response.ok) {
            setDeliveryTownships(response.data.data);
        }
    }

    useEffect(() => {
        getCustomer(id);
        getStates();
        return () => {
            setName('');
            setNameError('');
        }
    }, [id])

    useEffect(() => {
        if (stateId) {
            getDeliveryTownships();
        }
        return () => {
            setDeliveryTownships([]);
        }
    }, [stateId])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setAddressError(!address_line_1 ? 'This field is required' : '')
        if (name) {
            var response = await fetchData(id ? `api/v1/admin/customers/${id}` : 'api/v1/admin/customers', {
                name,
                phone,
                address_line_1,
                address_line_2,
                email,
                state_id: stateId,
                delivery_township_id: deliveryTownshipId,
            }, (id ? 'PUT' : 'POST'));
            if (response.ok) {
                history.push('/customers');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                    <CustomInput
                                        labelText="Phone"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: phone || '',
                                            onChange: e => setPhone(e.target.value),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: email || '',
                                            onChange: e => setEmail(e.target.value),
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <FormControl style={{ marginTop: "27px" }} fullWidth>
                                        <InputLabel id="state-label" shrink={stateId != null}>State</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            id="state-id"
                                            value={stateId}
                                            label="State"
                                            onChange={(e) => { setStateId(e.target.value) }}>
                                            {
                                                states.map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <FormControl style={{ marginTop: "27px" }} fullWidth>
                                        <InputLabel id="deliverytownship-label" shrink={deliveryTownshipId != null}>Township</InputLabel>
                                        <Select
                                            labelId="deliverytownship-label"
                                            id="deliveryTownshipId"
                                            value={deliveryTownshipId}
                                            label="Township"
                                            onChange={(e) => { setDeliveryTownshipId(e.target.value) }}>
                                            {
                                                deliveryTownships.map((s) => <MenuItem key={s.id} value={s.id}>{s.township}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Address Line 1"
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: address_line_1 || '',
                                            onChange: e => set_address_line_1(e.target.value),
                                        }}
                                        errorText={addressError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <CustomInput
                                        labelText="Address Line 2"
                                        id="address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: address_line_2 || '',
                                            onChange: e => set_address_line_2(e.target.value),
                                        }}
                                        errorText={addressError}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "customers_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(CustomerForm);