import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import useFetch, { getData, deleteData } from "components/fetch";
import { useScrollToTop } from "components/ScrollToTop";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function Deliveries({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [deliveries, setDeliveries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    useScrollToTop(deliveries);
    const { user } = useContext(AuthContext)


    var response = useFetch([], `api/v1/admin/delivery_townships/states`);

    const deleteDelivery = async (id) => {
        var response = await deleteData(`api/v1/admin/delivery_townships/${id}`);
        if (response.ok) {
            getDeliveryTownships();
        }
    }

    const getDeliveryTownships = async () => {
        var response = await getData(`api/v1/admin/delivery_townships?state_id=${selectedState}`)
        if (response && response.ok) {
            setDeliveries(response.data.data);
        }
    }


    useEffect(() => {
        if (response && response.ok) {
            setStates(response.data.data);
            setSelectedState(12); // select yangon as default
        }
    }, [response])



    useEffect(() => {
        getDeliveryTownships();
    }, [selectedState])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="state-label" shrink={selectedState != (null | '')}>State</InputLabel>
                                    <Select
                                        labelId="state-label"
                                        id="state-id"
                                        value={selectedState}
                                        label="State"
                                        onChange={(e) => { setSelectedState(e.target.value) }}>
                                        {
                                            states.map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <DataTable
                                    tableHeaderColor="primary"
                                    tableHead={["Township", "Fee", "Remark", "Action"]}
                                    tableData={
                                        deliveries.map((delivery, index) => ({
                                            data: [delivery.township, delivery.fee, delivery.remark],
                                            name: delivery.township,
                                            onClickEdit: () => history.push(`/delivery/edit/${delivery.id}`),
                                            onClickDelete: () => deleteDelivery(delivery.id),
                                            disableDelete: !checkPermission(user, "delivery_townships_delete"),

                                        }))
                                    }
                                    disableCreate={!checkPermission(user, "delivery_townships_create")}
                                    onClickCreate={() => history.push(`/delivery/create`)}
                                    disablePagination={true}
                                    disableSearch={true}
                                />
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(Deliveries)