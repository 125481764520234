import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DataTable from "components/Table/DataTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { deleteData } from "components/fetch";
import { useScrollToTop } from "components/ScrollToTop";
import useFetch from "components/fetch";
import { Button } from "@material-ui/core";
import { Security as SecurityIcon } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import { checkPermission } from "helper/helper";
import { AuthContext } from "context/authContext";

function Customers({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [customers, setCustomers] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(1);
    const [search, setSearch] = useState('');
    useScrollToTop(customers);
    const { user } = useContext(AuthContext)


    var response = useFetch([page, search], `api/v1/admin/customers?page=${page}`);

    const DeleteCustomer = async (id) => {
        var response = await deleteData(`api/v1/admin/customers/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }
    useEffect(() => {
        if (response && response.ok) {
            setCustomers(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
            setPer_page(response.data.meta.per_page);
        }
    }, [response])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", "Phone", "Email", "Address Line 1", "Action"]}
                            tableData={
                                customers.map((customer, index) => ({
                                    data: [customer.name, customer.phone, customer.email, customer.address_line_1],
                                    onClickEdit: () => history.push(`/customer/edit/${customer.id}`),
                                    onClickDelete: () => DeleteCustomer(customer.id),
                                    disableDelete: !checkPermission(user, "customers_delete"),
                                    name: customer.name,
                                    addtionalActions: (
                                        <>
                                            {
                                                checkPermission(user, "customers_update") ?
                                                    <Button className='iconbtn' color="info" onClick={() => history.push(`/customer/password/${customer.id}`)} tooltip={{ title: 'Password' }}><SecurityIcon /></Button>
                                                    : <></>
                                            }
                                        </>
                                    )
                                    // disableEdit: false
                                }))
                            }
                            disableCreate={true}
                            onClickCreate={() => history.push(`/customer/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={(event, value) => {
                                setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

export default withRouter(Customers)