import React, { useContext, useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import useFetch, { postFormData, deleteData } from "components/fetch";
import DataTable from "components/Table/DataTable";
import { useScrollToTop } from "components/ScrollToTop";
import CustomModal from "components/Modal/CustomModal";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import Button from "components/CustomButtons/Button.js";
import { PhotoLibraryOutlined as PhotoLibraryOutlinedIcon, ViewCarouselOutlined as ViewCarouselOutlinedIcon } from '@material-ui/icons';
import { checkPermission } from "helper/helper";
import { AuthContext } from "context/authContext";

function SubCategories({ history }) {
    const isLoggedIn = useIsLoggedIn();
    const [sub_categories, setSub_categories] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [image, setImage] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [openModal, setOpenModal] = useState(false);

    const { user } = useContext(AuthContext)


    useScrollToTop(sub_categories);
    var response = useFetch([page, search], `api/v1/admin/sub_categories?page=${page}`);

    const DeleteCategory = async (id) => {
        var response = await deleteData(`api/v1/admin/sub_categories/${id}`);
        if (response.ok) {
            setPage(0);
        }
    }

    const savePhoto = async (subCategoryId, img) => {
        var response = await postFormData(`api/v1/admin/sub_categories/${subCategoryId}/image`, { image: img.file });
        return response;
    }

    const handleOnImageChange = async (img) => {
        setImage(img);
        await savePhoto(selectedSubCategory.id, img);
    }

    const handleOpenImageModal = (subCategory) => {
        setSelectedSubCategory(subCategory)
        setOpenModal(true)
    }


    useEffect(() => {
        if (response && response.ok) {
            setSub_categories(response.data.data);
            setCount(response.data.meta.last_page);
            setPage(response.data.meta.current_page);
        }
    }, [response])


    useEffect(() => {
        if (selectedSubCategory)
            setImage(selectedSubCategory.image)
    }, [selectedSubCategory])

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <DataTable
                            tableHeaderColor="primary"
                            tableHead={["Name", "Remark", "Category", "Action"]}
                            tableData={
                                sub_categories.map((sub_category, index) => ({
                                    data: [sub_category.name, sub_category.remark, sub_category.category ? sub_category.category.name : ''],
                                    onClickEdit: () => history.push(`/sub_category/edit/${sub_category.id}`),
                                    onClickDelete: () => DeleteCategory(sub_category.id),
                                    disableDelete: !checkPermission(user, "sub_categories_delete"),
                                    name: sub_category.name,
                                    addtionalActions: (
                                        <>
                                            {
                                                checkPermission(user, "categories_update") ?
                                                    <Button className='iconbtn' color="info" onClick={() => handleOpenImageModal(sub_category)} tooltip={{ title: 'Images' }}><PhotoLibraryOutlinedIcon /></Button>
                                                    : <></>
                                            }
                                        </>
                                    )
                                }))
                            }
                            disableCreate={!checkPermission(user, "sub_categories_create")}
                            onClickCreate={() => history.push(`/sub_category/create`)}
                            count={count}
                            page={page}
                            onPaginationChange={async (event, value) => {
                                await setPage(value)
                            }}
                            onSearch={setSearch}
                        />
                    </CardBody>
                </Card>

                <CustomModal open={openModal} onClose={() => { setOpenModal(false) }} style={{ alignItems: 'flex-start', marginTop: 150 }} modalBodyStyle={{ width: 1000, maxWidth: '90%' }}>
                    <GridContainer>
                        <GridItem md={12} style={{ width: '100%' }}>
                            <p>Sub Category Image</p>
                            <hr />
                            {
                                <SingleUpload value={image} onChange={handleOnImageChange} dataURLKey="url"
                                    style={{ width: 400, height: 168 }}
                                    unremoveable
                                    labelText={`Image`}
                                />
                            }
                        </GridItem>
                    </GridContainer>
                </CustomModal>
            </GridItem>
        </GridContainer>
    );
}
export default withRouter(SubCategories);