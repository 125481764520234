import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ImageUploader from "components/ImageUploader/ImageUploader";
import RichEditor from '@methodexists/me-rich-editor';

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { FormControl } from "@material-ui/core";
import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import CustomAutoComplete from "components/CustomAutoComplete/CustomAutoComplete";
import { getData, putData, postFormData } from "components/fetch";
import { getBase64 } from "components/ImageUploader/ImageUploader";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { CustomCheckBox } from "components/CustomCheckBox/CustomCheckBox";
import resolution from "components/resolution";
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

const useStyles = makeStyles(styles);

function ProductForm({ history }) {
    const classes = useStyles();

    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [images, setImages] = useState([])
    const [imagesError, setImagesError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [category, setCategory] = useState(null)
    const [categoryError, setCategoryError] = useState('')
    const [sub_category, setSubCategory] = useState([])
    const [new_arrival, setNewArrival] = useState(false)
    const [featured, setFeatured] = useState(false)
    const [best_seller, setBestSeller] = useState(false)
    const [is_on_sale, setIsOnSale] = useState(false)
    const [short_description, setShort_description] = useState('')
    const [short_descriptionError, setShort_descriptionError] = useState('')
    const [full_description, setFull_description] = useState('')
    const [full_descriptionError, setFull_descriptionError] = useState('')
    const [published, setPublished] = useState(false)
    const [gems, setGems] = useState([])
    const [materials, setMaterials] = useState([])
    const { user } = useContext(AuthContext)


    const GetProduct = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/products/${id}`)
            if (response.ok) {
                var data = response.data.data;
                setName(data.name);
                setCategory({ label: data.category ? data.category.name : null, value: data.category.id });
                setSubCategory({ label: data.sub_category ? data.sub_category.name : null, value: data.sub_category ? data.sub_category.id : null });
                setNewArrival(data.is_new);
                setFeatured(data.featured);
                setBestSeller(data.is_bestseller);
                setShort_description(data.short_description);
                setFull_description(data.full_description);
                setPublished(data.published);
                setIsOnSale(data.is_on_sale);
                setGems(data.gems.map((g) => { return { label: g.name, value: g.id } }));
                setMaterials(data.materials.map((m) => { return { label: m.name, value: m.id } }));
            }
        }
    }

    useEffect(() => {
        setSubCategory([]);
    }, [category])

    useEffect(() => {
        GetProduct(id);
        return () => {
            setImages([]);
            setImagesError('');
            setName('');
            setNameError('');
            setCategory(null);
            setCategoryError('');
            setSubCategory(null);
            setNewArrival(0);
            setFeatured(0);
            setBestSeller(0);
            setIsOnSale(0);
            setShort_description('');
            setShort_descriptionError('');
            setFull_description('');
            setFull_descriptionError('');
            setPublished(0);
            setGems([]);
            setMaterials([]);
        }
    }, [id])

    useDidUpdateEffect(() => {
        setNameError(!name ? 'This field is required' : '')
        return () => {
            setNameError('')
        }
    }, [name])

    useDidUpdateEffect(() => {
        setCategoryError(!category ? 'This field is required' : '')
        return () => {
            setCategoryError('')
        }
    }, [category])

    useDidUpdateEffect(() => {
        setShort_descriptionError(!short_description ? 'This field is required' : '')
        return () => {
            setShort_descriptionError('')
        }
    }, [short_description])

    useDidUpdateEffect(() => {
        setFull_descriptionError(!full_description ? 'This field is required' : '')
        return () => {
            setFull_descriptionError('')
        }
    }, [full_description])

    const Save = async () => {
        setNameError(!name ? 'This field is required' : '')
        setCategoryError(!category ? 'This field is required' : '')
        setShort_descriptionError(!short_description ? 'This field is required' : '')
        setFull_descriptionError(!full_description ? 'This field is required' : '')
        setImagesError((!images || images.length <= 0) ? 'This field is required' : '')
        if (name && category && short_description && full_description) {
            var data = {
                name,
                category_id: category.value,
                sub_category_id: sub_category.value ?? '',
                gems: gems.map(x => x.value),
                materials: materials.map(x => x.value),
                is_new: new_arrival,
                featured,
                is_on_sale,
                is_bestseller: best_seller,
                short_description,
                full_description,
                published
            };
            if (id) { // PUT
                var response = await putData(`api/v1/admin/products/${id}`, data);
                if (response.ok) {
                    history.push('/products');
                }
            } else { // POST
                if (images && images.length > 0) {
                    data = {
                        ...data, images: images.map(x => {
                            return { image: x.file, order: 0 }
                        })
                    };
                    var response = await postFormData('api/v1/admin/products', data);
                    if (response.ok) {
                        history.push('/products');
                    }
                } else {
                    setImagesError((!images || images.length <= 0) ? 'This field is required' : '')
                }
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                {
                                    !id ?
                                        <GridItem xs={12} sm={12} md={12}>
                                            <ImageUploader
                                                labelText={'Products Images * - Click / Drop here'}
                                                value={images}
                                                onChange={(images, addUpdateIndex, action) => { setImages(images) }}
                                                errorText={imagesError}
                                                resolutionProps={resolution.product}
                                            />
                                        </GridItem>
                                        : <></>
                                }
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Name *"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name || '',
                                            onChange: e => setName(e.target.value),
                                            onBlur: e => setNameError(!name ? 'This field is required' : '')
                                        }}
                                        errorText={nameError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Category *"
                                        id="category"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={category}
                                        onChange={(event, value) => setCategory(value)}
                                        onBlur={e => setCategoryError(!category ? 'This field is required' : '')}
                                        url={`api/v1/admin/categories`}
                                        labelField='name'
                                        valueField='id'
                                        errorText={categoryError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Sub Category"
                                        id="sub_category"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={sub_category}
                                        onChange={(event, value) => setSubCategory(value)}
                                        url={`api/v1/admin/categories/${(category?.value || '0')}/sub_categories`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Gems"
                                        id="gems"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiple
                                        value={gems}
                                        onChange={(event, value) => setGems(value)}
                                        url={`api/v1/admin/gems`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomAutoComplete
                                        labelText="Materials"
                                        id="materials"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiple
                                        value={materials}
                                        onChange={(event, value) => setMaterials(value)}
                                        url={`api/v1/admin/materials`}
                                        labelField='name'
                                        valueField='id'
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Short Description *"
                                        id="shortdescription"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: short_description || '',
                                            onChange: e => setShort_description(e.target.value),
                                            onBlur: e => setShort_descriptionError(!short_description ? 'This field is required' : '')
                                        }}
                                        errorText={short_descriptionError}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='New Arrival' value={new_arrival} onChange={e => setNewArrival(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Featured' value={featured} onChange={e => setFeatured(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Best Seller' value={best_seller} onChange={e => setBestSeller(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='On Sale' value={is_on_sale} onChange={e => setIsOnSale(e.target.checked ? 1 : 0)} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControl
                                        fullWidth
                                        className={classes.formControl}
                                    >
                                        <InputLabel
                                            className={classes.labelRoot}
                                        >
                                            Full Description *
                                        </InputLabel>
                                        <RichEditor
                                            value={full_description}
                                            onChange={value => setFull_description(value)}
                                            onImageUpload={file => getBase64(file).then(data => data)}
                                            style={{ marginTop: 50 }}
                                        />
                                    </FormControl>
                                    {full_descriptionError !== undefined ? (
                                        <InputLabel error shrink>{full_descriptionError}</InputLabel>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <CustomCheckBox label='Publish' value={published} onChange={e => setPublished(e.target.checked ? 1 : 0)} />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "products_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default withRouter(ProductForm)