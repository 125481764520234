/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import {
  Dashboard as DashboardIcon,
  ViewCarousel as ViewCarouselIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Category as CategoryIcon,
  Label as LabelIcon,
  AddShoppingCart as AddShoppingCartIcon,
  ShoppingCart as ShoppingCartIcon,
  ShoppingBasket as ShoppingBasketIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  MoveToInbox as MoveToInboxIcon,
  AccountBox as AccountBoxIcon,
  ContactPhone as ContactPhoneIcon,
  DirectionsBike as DirectionsBikeIcon,
  MoneyOff as MoneyOffIcon,
  ExitToApp as ExitToAppIcon,
  BubbleChart as BubbleChartIcon,
  Crop169Outlined as Crop169OutlinedIcon,
  Grain as GrainIcon,
  AttachMoney as AttachMoneyIcon,
  Image as ImageIcon,
  Policy as PolicyIcon,
  AccountBalance as AccountBalanceIcon,
  GraphicEq,
  Group as GroupIcon,
  Contacts,
  SupervisedUserCircle,
  VerifiedUser
} from "@material-ui/icons";

import CategoryForm from "views/Category/CategoryForm";
import Categories from "views/Category/Categories";
import Products from "views/Product/Products";
import ProductForm from "views/Product/ProductForm";
import Login from "views/Auth/Login";
import SubCategories from "views/SubCategory/SubCategories";
import SubCategoryForm from "views/SubCategory/SubCategoryForm";
import ProductVariations from "views/Product/ProductVariations";
import Logout from "views/Auth/Logout";
import Home from "views/Home/Home";
import Orders from "views/Order/Orders";
import Customers from "views/Customer/Customers";
import DeliveryForm from "views/Delivery/DeliveryForm";
import Deliveries from "views/Delivery/Deliveries";
import Report from "views/Report/Reports";

import PaymentMethodForm from "views/PaymentMethod/PaymentMethodForm";
import PaymentMethods from "views/PaymentMethod/PaymentMethods";
import PaymentIcon from '@material-ui/icons/Payment';
import Gems from "views/Gems/Gems";
import GemForm from "views/Gems/GemForm";
import Materials from "views/Materials/Materials";
import MaterialForm from "views/Materials/MaterialForm";
import CarouselForm from "views/Carousel/CarouselForm";
import Carousels from "views/Carousel/Carousels";
import Privacy from "views/PrivacyAndPolicy/Privacy"
import Terms from "views/TermsAndCondition/Terms";
import CustomerForm from "views/Customer/CustomerForm";
import CustomerPasswordForm from "views/Customer/CustomerPasswordForm";
import TestimonialList from "views/Testimonial/TestimonialList";
import TestimonialForm from "views/Testimonial/TestimonialForm";
import ContactForm from "views/Contact/ContactForm";
import Users from "views/User/Users";
import UserForm from "views/User/UserForm";
import Roles from "views/Role/Roles";
import RoleForm from "views/Role/RoleForm";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Login,
    layout: "/auth",
  },
  {
    path: "/home",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: ViewCarouselIcon,
    component: Home,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircle,
    component: Users,
    layout: "/admin",
    protect: "users_list"
  },
  {
    path: "/user/create",
    name: "Create User",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircle,
    component: UserForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/user/edit/:id",
    name: "Edit User",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircle,
    component: UserForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/roles",
    name: "Role",
    rtlName: "لوحة القيادة",
    icon: VerifiedUser,
    component: Roles,
    layout: "/admin",
    protect: "roles_list"
  },
  {
    path: "/role/create",
    name: "Create Role",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircle,
    component: RoleForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/role/edit/:id",
    name: "Edit Role",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircle,
    component: RoleForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "لوحة القيادة",
    icon: AttachMoneyIcon,
    component: Orders,
    layout: "/admin",
    protect: "orders_list"
  },
  {
    path: "/categories",
    name: "Categories",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: Categories,
    layout: "/admin",
    protect: "categories_list"
  },
  {
    path: "/category/create",
    name: "Create Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: CategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/category/edit/:id",
    name: "Edit Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: CategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sub_categories",
    name: "Sub Categories",
    rtlName: "لوحة القيادة",
    icon: BubbleChartIcon,
    component: SubCategories,
    layout: "/admin",
    protect: "sub_categories_list"
  },
  {
    path: "/sub_category/create",
    name: "Create Sub Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: SubCategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/sub_category/edit/:id",
    name: "Edit Sub Category",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: SubCategoryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gems",
    name: "Gems",
    rtlName: "لوحة القيادة",
    icon: GrainIcon,
    component: Gems,
    layout: "/admin",
    protect: "gems_list"
  },
  {
    path: "/gem/create",
    name: "Create Gem",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: GemForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gem/edit/:id",
    name: "Edit Gem",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: GemForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/materials",
    name: "Materials",
    rtlName: "لوحة القيادة",
    icon: Crop169OutlinedIcon,
    component: Materials,
    layout: "/admin",
    protect: "materials_list"
  },
  {
    path: "/material/create",
    name: "Create material",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: MaterialForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/material/edit/:id",
    name: "Edit Material",
    rtlName: "لوحة القيادة",
    icon: CategoryIcon,
    component: MaterialForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: Products,
    layout: "/admin",
    protect: "products_list"
  },
  {
    path: "/product/create",
    name: "Create Product",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/product/edit/:id",
    name: "Edit Product",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/product/variations/:id",
    name: "Product Variations",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketIcon,
    component: ProductVariations,
    layout: "/admin",
    hide: true
  },
  {
    path: "/payment_methods",
    name: "Payment Methods",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethods,
    layout: "/admin",
    protect: "payment_methods_list"
  },
  {
    path: "/payment_method/create",
    name: "Create Payment Method",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethodForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/payment_method/edit/:id",
    name: "Edit Payment Method",
    rtlName: "لوحة القيادة",
    icon: PaymentIcon,
    component: PaymentMethodForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: Customers,
    layout: "/admin",
    protect: "customers_list"
  },
  {
    path: "/customer/edit/:id",
    name: "Edit Customer",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: CustomerForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/customer/password/:id",
    name: "Edit Customer Password",
    rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: CustomerPasswordForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/deliveries",
    name: "Deliveries",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: Deliveries,
    layout: "/admin",
    protect: "delivery_townships_list"
  },
  {
    path: "/delivery/create",
    name: "Create Delivery",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: DeliveryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/delivery/edit/:id",
    name: "Edit Delivery",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: DeliveryForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/reports",
    name: "Report",
    rtlName: "لوحة القيادة",
    icon: GraphicEq,
    component: Report,
    layout: "/admin",
  },
  {
    path: "/carousels",
    name: "Carousels",
    rtlName: "لوحة القيادة",
    icon: ImageIcon,
    component: Carousels,
    layout: "/admin",
    protect: "carousels_list"
  },
  {
    path: "/carousel/create",
    name: "Create Carousel",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: CarouselForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/carousel/edit/:id",
    name: "Edit Carousel",
    rtlName: "لوحة القيادة",
    icon: DirectionsBikeIcon,
    component: CarouselForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/privacy-policy",
    name: "Privacy and Policy",
    rtlName: "لوحة القيادة",
    icon: PolicyIcon,
    component: Privacy,
    layout: "/admin",
    protect: "privacy_update"
  },
  {
    path: "/terms-condition",
    name: "Terms and Condition",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: Terms,
    layout: "/admin",
    protect: "terms_update"
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    rtlName: "لوحة القيادة",
    icon: GroupIcon,
    component: TestimonialList,
    layout: "/admin",
    protect: "testimonials_list"
  },
  {
    path: "/testimonial/create",
    name: "Create Testimonials",
    rtlName: "لوحة القيادة",
    icon: GroupIcon,
    component: TestimonialForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/testimonial/edit/:id",
    name: "Edit Testimonials",
    rtlName: "لوحة القيادة",
    icon: GroupIcon,
    component: TestimonialForm,
    layout: "/admin",
    hide: true
  },
  {
    path: "/contact",
    name: "Contact",
    rtlName: "لوحة القيادة",
    icon: Contacts,
    component: ContactForm,
    layout: "/admin",
    protect: "contact_update"
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: ExitToAppIcon,
    component: Logout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
