import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useIsLoggedIn } from "components/Hooks/useIsLoggedIn";
import { Redirect, useLocation, useParams, withRouter } from "react-router-dom";
import { getData, fetchData, postFormData } from "components/fetch";
import { useDidUpdateEffect } from "components/Hooks/useDidUpdateEffect";
import { SingleUpload } from 'components/ImageUploader/ImageUploader';
import { AuthContext } from "context/authContext";
import { checkPermission } from "helper/helper";

function CarouselForm({ history }) {
    let { id } = useParams();
    const isLoggedIn = useIsLoggedIn();
    let location = useLocation();

    const [text, setText] = useState('')
    const [description, setDescription] = useState('')
    const [link, setLink] = useState('')
    const [image, setImage] = useState(null)
    const { user } = useContext(AuthContext)

    const GetCategory = async (id) => {
        if (id) {
            var response = await getData(`api/v1/admin/carousels/${id}`)
            if (response.ok) {
                setText(response.data.data.text);
                setImage(response.data.data.image)
                setDescription(response.data.data.description)
                setLink(response.data.data.link)
            }
        }
    }

    useEffect(() => {
        GetCategory(id);
        return () => {
            setText('');
            setImage(null);
            setDescription('');
            setLink('');
        }
    }, [id])

    const Save = async () => {
        if (image) {
            var response = await postFormData(id ? `api/v1/admin/carousels/${id}` : 'api/v1/admin/carousels', { text, description, link, image: image.file });
            if (response.ok) {
                return history.push('/carousels');
            }
        }
    }

    let { from } = location.state || { from: { pathname: "/login" } };
    if (!isLoggedIn) return <Redirect to={from} />
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Text"
                                        id="text"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: text || '',
                                            onChange: e => setText(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Description"
                                        id="description"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: description || '',
                                            onChange: e => setDescription(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Link"
                                        id="link"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: link || '',
                                            onChange: e => setLink(e.target.value)
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url"
                                        style={{ width: 400, height: 168 }}
                                        unremoveable
                                        labelText={`Image`}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {
                                checkPermission(user, "carousels_update") &&
                                <Button color="primary" onClick={Save}>Save</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export default withRouter(CarouselForm);