import React, { useEffect, useState } from 'react'
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import useFetch from 'components/fetch';

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        MenuProps,
        error,
        success,
        value,
        onChange,
        data
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });
    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + " " + classes.formControl}
        >
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <Select
                value={value}
                onChange={onChange}
                classes={{
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses,
                }}
                {...MenuProps}
                id={id}
                {...inputProps}
            >
                {
                    data.map((item, index) =>
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    )
                }
            </Select>
            {error ? (
                <Clear className={classes.feedback + " " + classes.labelRootError} />
            ) : success ? (
                <Check className={classes.feedback + " " + classes.labelRootSuccess} />
            ) : null}
        </FormControl>
    );
}

CustomSelect.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
};

// incomplete yet
export function CustomPaginateSelect(props) {
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [value, setValue] = useState('')

    const useStyles = makeStyles({
        list: {
            maxHeight: 300
        },
    });
    const classes = useStyles();

    var response = useFetch(page, `${props.url}?page=${page}`);
    useEffect(() => {
        if (response && response.ok) {
            setData(response.data.data);
            setPage(response.data.meta.current_page);
        }
    }, [response])
    return (
        <div className='customPaginateSelect'>
            <CustomSelect
                {...props}
                MenuProps={{
                    classes: {
                        list: classes.list
                    }
                }}
                data={data.map(x => ({ label: x[props.labelField], value: x[props.valueField] }))}
            />
        </div>
    )
}
